import witlogo from "../media/wit_logo.png"
import "./NotFound.css"

export default function notFound404() {
    return (
        <div className="notfound_body">

            <img src={witlogo} alt="witlogo"/>

            <p>- 404 NotFound -</p>
        </div>
    );
}