import React from 'react';
import ReactDOM from 'react-dom/client';
import reset from "styled-reset";
import { createGlobalStyle } from "styled-components";

import { BrowserRouter } from "react-router-dom";
import './index.css';
import routes from './router';
import reportWebVitals from './reportWebVitals';

// css reset
const GlobalStyle = createGlobalStyle`
  ${reset}
  /* other styles */
`;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <GlobalStyle />
    <BrowserRouter>{routes}</BrowserRouter>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();